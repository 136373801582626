<template>
    <div class="house-detail">
        <app-detail :opt="opt" ref="detail"></app-detail>
    </div>
</template>
<script>
    import ruleVerification from "@utils/ruleVerification.js"
    let landlordSpace = 'XCGG002'; //房东广告位编码
    let tenantSpace = 'XCGG003'; //房客广告位编码
    let tenantRotation = 'XCGG004'; //房客首页轮播图
    let tenantShopping = 'XCGG005'; //房客首页商城列表
    let tenantOpenDoorBottom = 'XCGG006'; //房客开门页底部广告
    let tenantTopAd = 'XCGG007'; //我的页面顶部广告
    let validAdName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback(new Error("请输入广告名称"));
        } else {
            //支持输入中英文字母，汉字，1~24个字符
            let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,24}$/;
            if (!reg.test(value)) {
                if(value.length <= 24) {
                    callback(new Error("广告名称格式不对"));
                }else if(value.length > 24){
                    callback(new Error("广告名称太长"));
                }
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                adRangeVal: "",
                adSpace: "",
                arr: [],
                allFlag: false,
                opt: {
                    title: "广告详情",
                    form: [{
                            label: "ID",
                            key: "id",
                            disabled: true,
                            notShow: true
                        },
                        {
                            label: "广告位",
                            key: "spaceCode",
                            type: "select",
                            isCol50: true,
                            isWidth67: true,
                            opt: {
                                list: [],
                                change(e, cb, form) {
                                    _this.adSpace = e;
                                    _this.opt.form.forEach((item, index) => {
                                        if (item.key == "adRange") {
                                            let adRangeList = [];
                                            let defaultValue = "";
                                            if (_this.adSpace == landlordSpace) {
                                                defaultValue = "3";
                                                adRangeList = [{
                                                        label: "全部房东",
                                                        id: "3",
                                                        disabled: false,
                                                    },
                                                    {
                                                        label: "部分房东",
                                                        id: "4",
                                                        disabled: true
                                                    }
                                                ]
                                            } else if ((_this.adSpace == tenantSpace || _this.adSpace ==
                                                    tenantRotation || _this.adSpace == tenantShopping ||
                                                    _this.adSpace == tenantOpenDoorBottom || _this
                                                    .adSpace == tenantTopAd)) {
                                                defaultValue = "1";
                                                adRangeList = [{
                                                        label: "全部房客",
                                                        id: "1",
                                                        disabled: false,
                                                    },
                                                    {
                                                        label: "部分房客",
                                                        id: "2",
                                                        disabled: true
                                                    }
                                                ]
                                            } else {
                                                defaultValue = _this.adRangeVal ? _this.adRangeVal : '1';
                                                adRangeList = [{
                                                    label: "全部房客",
                                                    id: "1",
                                                    disabled: false,
                                                }, {
                                                    label: "部分房客",
                                                    id: "2",
                                                    disabled: false,
                                                }]
                                            }
                                            cb({
                                                key: "adRange",
                                                type: 'adRangeType',
                                                defaultValue: defaultValue,
                                                list: adRangeList
                                            });
                                        }
                                    });
                                    // 当广告位为 房东banner或者房客banner时，广告链接对象默认值为文章
                                    // if (form) {
                                    //     if (!(e == landlordSpace || e == tenantSpace || e == tenantRotation || e ==
                                    //             tenantShopping)) {
                                    //         form['adLinkType'] = "";
                                    //         _this.adRangeVal = "";
                                    //     } else {
                                    //         form['adLinkType'] = 1;
                                    //     }
                                    // }
                                }
                            },
                            rules: [{
                                required: true
                            }]
                        },
                        {
                            label: "广告名称",
                            key: "adName",
                            // maxlength: 24,
                            rules: [{
                                required: true,
                                validator: validAdName,
                                trigger: ['blur']
                            }]
                        },
                        {
                            label: "上传广告图",
                            key: "picUrl",
                            type: "upload-img-only",
                            rules: [{
                                required: true
                            }],
                            opt: {
                                url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                                alt: {
                                    'XCGG001': '仅支持.jpg格式，图片尺寸：590*657px,限制1M大小',
                                    'XCGG002': '仅支持.jpg格式，图片尺寸：690*330px,限制1M大小',
                                    'XCGG003': '仅支持.jpg格式，图片尺寸：686*270px,限制1M大小',
                                    'XCGG004': '仅支持.jpg格式，图片尺寸：750*516px,限制1M大小',
                                    'XCGG005': '仅支持.jpg格式，图片尺寸：706*300px,限制1M大小',
                                    'XCGG006': '仅支持.jpg格式，图片尺寸：220*143px,限制1M大小',
                                    'XCGG007': '仅支持.jpg格式，图片尺寸：686*100px,限制1M大小',
                                },
                                altKey: 'spaceCode', //根据选择显示不同的提示的key
                                limitwidthLength: //限制长宽
                                {
                                    'XCGG001': ['590', '657'],
                                    'XCGG002': ['690', '330'],
                                    'XCGG003': ['686', '270'],
                                    'XCGG004': ['750', '516'],
                                    'XCGG005': ['706', '300'],
                                    'XCGG006': ['220', '143'],
                                    'XCGG007': ['686', '100'],
                                },
                                limitType: ["image/jpeg"], //限制图片类型
                                limitSize: 1, //限制图片大小1M
                                beforeUploadTip: '请先选择广告位',
                                upload(file) {
                                    console.log('----------------upload:', file);
                                    return true;
                                },
                                remove(file, fileList) {
                                    console.log('-----------remove:', file, fileList)
                                    return true;
                                }
                            }
                        },
                        {
                            label: "应用范围",
                            key: "adRange",
                            type: "radio",
                            defaultValue: "1",
                            rules: [{
                                required: true
                            }],
                            opt: {
                                list: [{
                                    label: "全部房客",
                                    id: "1",
                                    disabled: false,
                                }, {
                                    label: "部分房客",
                                    id: "2",
                                    disabled: false,
                                }]
                            },

                        },
                        {
                            label: "授权房源",
                            type: 'select-cascader-advert',
                            value: [],
                            filters: {
                                key: "adRange",
                                value: "2"
                            },
                            key: "apartmentIds",
                            opt: {
                                props: {
                                    oneLoad: _this.oneLoad,
                                    twoLoad: _this.twoLoad,
                                    userId: _this.$route.params.id,
                                    querySearchAsync: _this.querySearchAsync,
                                    getAllFlag: _this.getAllFlag
                                }
                            }
                        },
                        // {
                        //     label: " ",
                        //     key: "apartmentIds",
                        //     type: "select-filterables",
                        //     filters: {
                        //         key: "adRange",
                        //         value: "2"
                        //     },
                        //     opt: {
                        //         list: [],
                        //         change(arr, cb, form) {
                        //             if (form && form.hasOwnProperty("apartmentIds")) {
                        //                 // if (arr.length > 0 && arr[arr.length - 1] == 0) {
                        //                 //     form["apartmentIds"] = ["0"];
                        //                 // } else if (arr.length > 1 && arr[0] == 0) {
                        //                 //     arr.shift();
                        //                 //     form["apartmentIds"] = arr;
                        //                 // } else if (arr[0] == "未授权房源") {
                        //                 //     arr.shift();
                        //                 //     form["apartmentIds"] = arr;
                        //                 // }
                        //                 form["apartmentIds"] = arr;
                        //             } else {
                        //                 console.warn("属性不存在：fapartmentIds");
                        //             }
                        //         }
                        //     }
                        // },
                        {
                            label: "广告链接对象",
                            key: "adLinkType",
                            type: "select",
                            defaultValue: 1,
                            opt: {
                                list: [{
                                        label: "文章",
                                        value: 1,
                                    },
                                    {
                                        label: "外部小程序",
                                        value: 2
                                    },
                                    {
                                        label: "内部小程序",
                                        value: 3
                                    },
                                    {
                                        label: "客服消息",
                                        value: 4
                                    },
                                    {
                                        label: "半屏小程序",
                                        value: 5
                                    }
                                ]
                            },
                        },
                        {
                            label: "页面标题",
                            key: "articlePageTitle",
                            filters: {
                                key: "adLinkType",
                                value: 1
                            }
                        },
                        {
                            label: "广告链接",
                            key: "articlePageAdUrl",
                            maxlength: 200,
                            filters: {
                                key: "adLinkType",
                                value: 1
                            }
                        },
                        {
                            label: "小程序名称",
                            key: "externalAppletTitle",
                            filters: {
                                key: "adLinkType",
                                value: 2
                            },
                        },
                        {
                            label: "小程序名称",
                            key: "halfScreenTitle",
                            filters: {
                                key: "adLinkType",
                                value: 5
                            },
                        },
                        {
                            label: "小程序appid",
                            key: "appId",
                            filters: {
                                key: "adLinkType",
                                value: 2
                            },
                        },
                        {
                            label: "小程序appid",
                            key: "appId",
                            filters: {
                                key: "adLinkType",
                                value: 5
                            },
                        },
                        {
                            label: "小程序页面链接",
                            key: "externalAppletAdUrl",
                            maxlength: 200,
                            filters: {
                                key: "adLinkType",
                                value: 2
                            },
                        },
                        {
                            label: "小程序页面链接",
                            key: "adUrl",
                            maxlength: 200,
                            filters: {
                                key: "adLinkType",
                                value: 3
                            },
                        },
                        {
                            label: "小程序页面链接",
                            key: "halfScreenAppletAdUrl",
                            maxlength: 200,
                            filters: {
                                key: "adLinkType",
                                value: 5
                            },
                        },
                        {
                            label: "弹框消息名称",
                            key: "messageName",
                            filters: {
                                key: "adLinkType",
                                value: 4
                            },
                            rules: [{
                                required: true,
                                validator: ruleVerification.messageName,
                                trigger: ['change']
                            }]
                        },
                        {
                            label: "弹框消息文字",
                            key: "messageText",
                            filters: {
                                key: "adLinkType",
                                value: 4
                            },
                            rules: [{
                                required: true,
                                validator: ruleVerification.messageText,
                                trigger: ['change']
                            }]
                        },
                        {
                            label: "弹框按钮文字",
                            key: "buttonText",
                            filters: {
                                key: "adLinkType",
                                value: 4
                            },
                            rules: [{
                                required: true,
                                validator: ruleVerification.buttonText,
                                trigger: ['change']
                            }]
                        },
                        {
                            label: "客服消息链接地址",
                            key: "customerServiceAdUrl",
                            maxlength: 10000,
                            filters: {
                                key: "adLinkType",
                                value: 4
                            },
                            rules: [{
                                required: true,
                            }]
                        },
                        {
                            label: "客服消息链接logo",
                            key: "linkLogo",
                            type: "upload-img-only",
                            filters: {
                                key: "adLinkType",
                                value: 4
                            },
                            rules: [{
                                required: true
                            }],
                            opt: {
                                url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                                alt: '仅支持.jpg格式，图片大小80*80px',
                                limitwidthLength: ['80', '80'],
                                limitType: ["image/jpeg"], //限制图片类型
                                limitSize: 1, //限制图片大小1M
                                upload(file) {
                                    console.log(file);
                                    return true;
                                },
                                remove(file, fileList) {
                                    return true;
                                }
                            }
                        },
                        {
                            label: "客服消息链接标题",
                            key: "customerServiceTitle",
                            filters: {
                                key: "adLinkType",
                                value: 4
                            },
                            rules: [{
                                required: true,
                                validator: ruleVerification.messageTitle,
                                trigger: ['change']
                            }]
                        },
                        {
                            label: "客服消息链接描述",
                            key: "linkDesc",
                            maxlength: 200,
                            filters: {
                                key: "adLinkType",
                                value: 4
                            },
                            rules: [{
                                required: true,
                                // validator: ruleVerification.messageName,
                                // trigger: ['change']
                            }]
                        },
                        // {
                        //     label: "弹框图片",
                        //     key: "picture",
                        //     type: "upload-img-only",
                        //     filters: {
                        //         key: "adLinkType",
                        //         value: 4
                        //     },
                        //     rules: [{
                        //         required: true
                        //     }],
                        //     opt: {
                        //         url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                        //         alt: '仅支持.jpg格式，图片大小423*339px',
                        //         limitwidthLength: ['423', '339'],
                        //         limitType: ["image/jpeg"], //限制图片类型
                        //         limitSize: 1, //限制图片大小1M
                        //         upload(file) {
                        //             console.log(file);
                        //             return true;
                        //         },
                        //         remove(file, fileList) {
                        //             return true;
                        //         }
                        //     }
                        // },
                        {
                            label: "广告排序",
                            key: "sort",
                            type: "number",
                            isCol50: true,
                            isWidth67: true,
                            rules: [{
                                required: true
                            }]
                        },
                        {
                            label: "备注",
                            key: "remark",
                            type: "textarea"
                        },
                        {
                            label: "广告状态",
                            key: "state",
                            type: "switch",
                            defaultValue: true,
                            opt: {
                                activeText: "上架",
                                inactiveText: "下架"
                            },
                            rules: [{
                                required: true
                            }]
                        }
                    ],
                    buttons: [{
                        name: "提交",
                        show: ["add", "edit"],
                        cb(form) {
                            // 若选择了全部，只提交空
                            let dtoApartmentIds = [];
                            // if (form.apartmentIds && form.apartmentIds.indexOf("0") != -1) {
                            //     dtoApartmentIds = [];
                            // } else if (form.apartmentIds && (form.apartmentIds.length == 0 || form.apartmentIds[
                            //         0] == "未授权房源")) {

                            //     dtoApartmentIds = ["1"];
                            // } else {
                            //     dtoApartmentIds = form.apartmentIds ?
                            //         JSON.parse(JSON.stringify(form.apartmentIds)) :
                            //         null;
                            // }


                            let apartmentIdsArr = [];
                            if (form.apartmentIds && form.apartmentIds.length) {
                                form.apartmentIds.forEach(it1 => {
                                    it1.citys.forEach(it2 => {
                                        it2.apartmentIds.forEach(it3 => {
                                            apartmentIdsArr.push(it3.id);
                                        })
                                    })
                                })
                            }

                            if (form.adRange == 1) {
                                apartmentIdsArr = [];
                            } else if (form.adRange == 2 && apartmentIdsArr.length == 0) {
                                _this.$message({
                                    showClose: true,
                                    message: "请选择房源",
                                    type: "warning"
                                });
                                return;
                            }
                            // if (form.adRange == 2 && dtoApartmentIds.length == 0) {
                            //     _this.$message({
                            //         showClose: true,
                            //         message: "请选择房源",
                            //         type: "warning"
                            //     });
                            //     return;
                            // }
                            let dto = {
                                sort: form.sort.toString(),
                                // apartmentIds: dtoApartmentIds,
                                apartmentIds: apartmentIdsArr,
                                adName: form.adName,
                                adRange: form.adRange,
                                remark: form.remark,
                                state: form.state ? 1 : 0,
                                adLinkType: form.adLinkType,
                                appId: form.appId,
                            };
                            if (form.adLinkType == 1) { //文章
                                dto.adLinkTitle = form.articlePageTitle ? form.articlePageTitle : "";
                                dto.adUrl = form.articlePageAdUrl ? form.articlePageAdUrl : "";
                            } else if (form.adLinkType == 2) { //外部小程序
                                dto.adLinkTitle = form.externalAppletTitle ? form.externalAppletTitle : "";
                                dto.adUrl = form.externalAppletAdUrl ? form.externalAppletAdUrl : "";
                            } else if (form.adLinkType == 4) { // 客服消息
                                dto.adLinkTitle = form.customerServiceTitle ? form.customerServiceTitle : "";
                                dto.adUrl = form.customerServiceAdUrl ? form.customerServiceAdUrl : "";
                            } else if (form.adLinkType == 3) { //内部小程序
                                dto.adUrl = form.adUrl ? form.adUrl : "";
                            } else if (form.adLinkType == 5) { //外部小程序
                                dto.adLinkTitle = form.halfScreenTitle ? form.halfScreenTitle : "";
                                dto.adUrl = form.halfScreenAppletAdUrl ? form.halfScreenAppletAdUrl : "";
                            }
                            _this.adSpaceList.forEach(item => {
                                if (item.spaceCode == form.spaceCode) {
                                    dto.adSpaceId = item.id;
                                }
                            })
                            dto.picUrl = form.picUrl.length > 0 ? form.picUrl[0].url : "";
                            if (form.adLinkType == 4) {
                                dto.linkLogo = form.linkLogo.length > 0 ? form.linkLogo[0].url : "";
                                // dto.picture = form.picture.length > 0 ? form.picture[0].url : "";
                                dto.messageName = form.messageName;
                                dto.messageText = form.messageText;
                                dto.buttonText = form.buttonText;
                                dto.linkDesc = form.linkDesc;
                            }
                            // return;
                            let operateName = _this.$route.params.type == 'add' ? '新增广告' : '编辑广告';
                            let url = "landlord-service/advertiseInfo/add";
                            let message = "添加广告成功";
                            if (form.id) {
                                url = "landlord-service/advertiseInfo/update";
                                dto.id = form.id;
                                message = "编辑广告成功";
                            }
                            _this.post(url, dto, {
                                operatorFlage: true,
                                operateName: operateName,
                            }).then(function () {
                                _this.$message({
                                    showClose: true,
                                    message: message,
                                    type: "success"
                                });
                                _this.$back();
                            });
                        }
                    }],
                    get(opt) {
                        Promise.all([_this.getAdvertisingSpace()]).then(values => {
                            let params = {
                                "city": "",
                                "province": "",
                                "type": ""
                            }
                            _this.post("landlord-service/advertiseInfo/detail/" + opt.id, params).then(
                                data => {
                                    data.imgs = [];
                                    if (data.picUrl) {
                                        data.picUrl = [{
                                            url: data.picUrl
                                        }];
                                    } else {
                                        data.picUrl = []
                                    }
                                    if (data.picture) {
                                        data.picture = [{
                                            url: data.picture
                                        }];
                                    } else {
                                        data.picture = []
                                    }
                                    if (data.linkLogo) {
                                        data.linkLogo = [{
                                            url: data.linkLogo
                                        }];
                                    } else {
                                        data.linkLogo = []
                                    }
                                    data.state = data.state == 1 ? true : false;
                                    _this.adSpaceList.forEach(item => {
                                        // 根据返回的adSpaceId 找到对应的spaceCode
                                        if (item.id == Number(data.adSpaceId)) {
                                            data.spaceCode = item.spaceCode;
                                        }
                                    })
                                    _this.adRangeVal = data.adRange;
                                    // let tempArr = [];
                                    // if (data.apartmentIds) {
                                    //     data.apartmentIds.forEach((item, index) => {
                                    //         tempArr.push(item[0]);
                                    //     });
                                    // }
                                    // if (tempArr && tempArr.length == 1 && tempArr[0] == "1") {
                                    //     data.apartmentIds = ["未授权房源"];
                                    // } else {
                                    //     data.apartmentIds = tempArr.length ? tempArr : ["0"];
                                    // data.apartmentIds = tempArr;
                                    // }
                                    data.adLinkType = data.adLinkType ? data.adLinkType : '';
                                    if (data.adLinkType == 1) { //文章
                                        data.articlePageTitle = data.adLinkTitle;
                                        data.articlePageAdUrl = data.adUrl;
                                    } else if (data.adLinkType == 2) { //外部小程序
                                        data.externalAppletTitle = data.adLinkTitle;
                                        data.externalAppletAdUrl = data.adUrl;
                                    } else if (data.adLinkType == 4) { //客服消息
                                        data.customerServiceTitle = data.adLinkTitle;
                                        data.customerServiceAdUrl = data.adUrl;
                                    } else if (data.adLinkType == 5) { //外部小程序
                                        data.halfScreenTitle = data.adLinkTitle;
                                        data.halfScreenAppletAdUrl = data.adUrl;
                                    }
                                    opt.cb(data);
                                });
                        })

                    },
                    //添加的时候，只有请求完接口才可输入信息，解决切换部分房客的时候选择房源下拉框没有数据的bug
                    addInit(opt) {
                        Promise.all([_this.getAdvertisingSpace()]).then(values => {
                            opt.cb(true);
                        })

                    }
                },
            };
        },
        created() {

        },
        activated() {
            // this.getApartmentList();
            // this.getAdvertisingSpace();
            this.$refs.detail.title = ["新增广告", "编辑广告", "广告详情"][{
                add: 0,
                edit: 1,
                show: 2
            } [this.$route.params.type]];
            // this.opt.form.forEach((item, index) => {
            //     if (this.$route.params.type == "add" && item.key == "id") {
            //         item.notShow = true;
            //     } else {
            //         item.notShow = false;
            //     }
            //     //应用范围默认可选择
            //     if (item.key == "adRange") {
            //         item.opt.list.forEach(it => {
            //             it.disabled = false;
            //         })
            //     }
            // })
        },
        methods: {
            getAllFlag(callback) {
                callback(this.allFlag)
            },
            oneLoad() {
                var dto = {
                    type: 'all'
                };
                return new Promise((resolve, reject) => {
                    // this.get("/landlord-service/getAllPovinceAndCity", dto).then(
                    this.post("/landlord-service/advertiseInfo/detail/0", dto).then(
                        res => {
                            let result = res.adApartments;
                            result = JSON.parse(JSON.stringify(result).replace(/province/g,
                                "name"));
                            result = JSON.parse(JSON.stringify(result).replace(/code/g, "id"));
                            result = JSON.parse(JSON.stringify(result).replace(/citys/g,
                                "children"));
                            result = JSON.parse(JSON.stringify(result).replace(/city/g,
                                "name"));
                            result = JSON.parse(JSON.stringify(result).replace(/apartmentIds/g,
                                "children"));
                            console.log('reresults', result);
                            resolve(result)
                        }).catch(() => {
                        reject();
                    })
                })
            },
            twoLoad(params) {
                let idx = 0;
                let _this = this;
                return new Promise((resolve, reject) => {
                    if (params.firstRequest) //第一次请求
                    {
                        function postInfo(dto) {
                            _this.post("/landlord-service/advertiseInfo/detail/" + _this
                                .$route.params.id, dto).then(
                                //  _this.post("/landlord-service/room/querySelectedHomeInfo", dto).then(
                                res => {
                                    if (res && res.length == 0) {
                                        idx++;
                                        postInfo({
                                            apartmentId: params.apartmentIds[idx].id,
                                            type: 'all'
                                        });
                                    } else {
                                        res.adApartments.forEach(it1 => {
                                            it1.citys.forEach(it2 => {
                                                it2.apartmentIds.forEach(it3 => {
                                                    it3.name = it3.name + '（' + it3
                                                        .realname + '）';
                                                })
                                            })
                                        })
                                        console.log('res.adApartments', res.adApartments);
                                        let result = res.adApartments;
                                        result = JSON.parse(JSON.stringify(result).replace(/province/g,
                                            "name"));
                                        result = JSON.parse(JSON.stringify(result).replace(/code/g, "id"));
                                        result = JSON.parse(JSON.stringify(result).replace(/citys/g,
                                            "children"));
                                        result = JSON.parse(JSON.stringify(result).replace(/city/g,
                                            "name"));
                                        result = JSON.parse(JSON.stringify(result).replace(/apartmentIds/g,
                                            "children"));
                                        resolve(result);
                                    }
                                })
                        }
                        postInfo({
                            type: 'all'
                        })
                    } else {
                        this.post("/landlord-service/advertiseInfo/detail/" + this
                            .$route.params.id, params).then(
                            // this.post("/landlord-service/room/querySelectedHomeInfo", params).then(
                            res => {
                                res.adApartments.forEach(it1 => {
                                    it1.citys.forEach(it2 => {
                                        it2.apartmentIds.forEach(it3 => {
                                            it3.name = it3.name + '（' + it3
                                                .realname + '）';
                                        })
                                    })
                                })
                                let result = res.adApartments;
                                result = JSON.parse(JSON.stringify(result).replace(/province/g, "name"));
                                result = JSON.parse(JSON.stringify(result).replace(/code/g, "id"));
                                result = JSON.parse(JSON.stringify(result).replace(/citys/g, "children"));
                                result = JSON.parse(JSON.stringify(result).replace(/city/g, "name"));
                                result = JSON.parse(JSON.stringify(result).replace(/apartmentIds/g,
                                    "children"));
                                resolve(result);
                            })
                    }

                })
            },
            //queryString 为在框中输入的值
            //callback 回调函数,将处理好的数据推回
            querySearchAsync(queryString, callback) {
                this.$nextTick(() => {
                    var dto = {
                        name: queryString,
                        pageNumber: "1",
                        pageSize: "99999"
                    };
                    this.post("landlord-service/apartment/queryPagesApartment", dto).then(
                        res => {
                            let arr = []
                            res.forEach(item => {
                                arr.push({
                                    value: item.name + "（" + item.landlordName + "）",
                                    label: item.id
                                })
                            })
                            callback({
                                queryString: queryString,
                                result: arr
                            });
                        })
                })
            },
            //获取广告位
            getAdvertisingSpace() {
                return new Promise((resolve, reject) => {
                    this.post("landlord-service/advertiseSpace/queryAdSpace", {}).then(res => {
                        res.forEach(item => {
                            item.value = item.spaceCode;
                            item.label = item.location;
                        })
                        this.adSpaceList = res;
                        this.opt.form.forEach(item => {
                            if (item.key == "spaceCode") {
                                item.opt.list = res;
                            }
                        })
                        resolve(res)
                    })
                })
            },
            //获取小区列表
            getApartmentList(cityId) {
                var dto = {
                    pageNumber: "1",
                    pageSize: "99999"
                };
                return new Promise((resolve, reject) => {
                    this.post("landlord-service/apartment/queryPagesApartment", dto).then(
                        res => {
                            this.opt.form.forEach(data => {
                                if (data.key === "apartmentIds") {
                                    // let arr = [{
                                    //     label: "全部",
                                    //     value: "0"
                                    // }]
                                    res.forEach(item => {
                                        item.label = item.name + "（" + item.landlordName +
                                            "）";
                                        item.value = item.id;
                                    });
                                    // data.opt.list = arr.concat(res);
                                    data.opt.list = res;
                                }
                            });
                            resolve(res)
                        }
                    );
                });
            },

        }
    };
</script>
<style lang="scss" scoped>
    tr {
        text-align: center;

        td {
            width: 300px;
        }
    }

    span {
        font-size: 14px;
        color: #303133;
    }

    el-button {
        width: 60%;
    }

    .grid-content {
        width: 60%;
        text-align: center;
        border-radius: 4px;
        min-height: 36px;
        line-height: 36px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }
</style>